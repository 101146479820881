/* This File is for {purpose} */

<template>
  <v-layout wrap justify-center class="home-container" fill-height pa-2>
    <v-flex xs2 text-center align-self-center>
      <v-btn
        light
        depressed
        :ripple="false"
        color="primary"
        :to="{ name: 'login' }"
      >
        Login
      </v-btn>
    </v-flex>
    <v-flex xs2 text-center align-self-center>
      <v-btn
        light
        depressed
        :ripple="false"
        color="primary"
        :to="{ name: 'admin.login' }"
      >
        Admin Login
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped></style>
