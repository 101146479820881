var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "home-container",
      attrs: { wrap: "", "justify-center": "", "fill-height": "", "pa-2": "" },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs2: "", "text-center": "", "align-self-center": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                light: "",
                depressed: "",
                ripple: false,
                color: "primary",
                to: { name: "login" },
              },
            },
            [_vm._v(" Login ")]
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs2: "", "text-center": "", "align-self-center": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                light: "",
                depressed: "",
                ripple: false,
                color: "primary",
                to: { name: "admin.login" },
              },
            },
            [_vm._v(" Admin Login ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }